<template>
  <modal
    :class="alert?.classes"
    v-bind="$attrs"
    ref="modal"
    @close="closeAlert"
    :hide-x="hideX || false"
  >
    <p
      v-if="title"
      :class="[
        'text-lg sm:text-2xl lg:text-3xl text-left font-medium',
        { 'text-center': singleMessage },
      ]"
      v-html="title"
    ></p>
    <ul v-if="messages.length > 0" class="flex flex-col gap-y-3 sm:gap-y-5">
      <li
        v-for="(message, index) in messages"
        :key="index"
        class="m-0 w-full text-base sm:gap-x-6 sm:text-lg md:gap-x-8 lg:text-xl"
        :class="{ 'text-center': singleMessage }"
        v-html="message"
      />
    </ul>
    <div
      class="flex flex-col gap-5 items-center justify-around w-full font-medium"
      v-if="buttonText || cancelButtonText"
    >
      <base-button
        v-if="buttonText"
        @click="onButtonClick"
        class="min-w-[7rem] w-full leading-none"
      >
        {{ buttonText }}
      </base-button>
      <p
        v-if="cancelButtonText"
        @click="onCancelClick"
        class="min-w-[7rem] text-center"
      >
        {{ cancelButtonText }}
      </p>
    </div>
    <slot />
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import useKeyboardInput from '@/use/use-keyboard-input'
import { Comment, computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { key } from '../store'
// import { useI18n } from 'vue-i18n'

export default {
  inheritAttrs: false,

  components: { Modal },

  setup(props, { emit, slots }) {
    const store = useStore(key)
    // const { t } = useI18n()

    const alert = computed(() => store.getters['alert'])

    const modal = ref(null)

    watchEffect(() => {
      if (!modal.value) return

      if (alert.value && modal.value) modal.value.show()
      else modal.value.close()
    })

    const computedProps = {
      singleMessage: computed(() => {
        return !alert.value?.messages || alert.value?.messages.length === 1
      }),
      title: computed(() => {
        return alert.value?.title
      }),
      messages: computed(() => {
        return alert.value?.messages || []
      }),
      buttonText: computed(() => {
        return alert.value?.buttonText === undefined
          ? 'Close'
          : alert.value?.buttonText
      }),
      cancelButtonText: computed(() => {
        return alert.value?.cancelButtonText
      }),
      hideX: computed(() => {
        return alert.value?.hideX
      }),
      hasSlot: computed(() => {
        return (
          slots.default &&
          slots.default().findIndex(o => o.type !== Comment) !== -1
        )
      }),
    }

    const closeAlert = () => {
      emit('close')
      store.dispatch('resetAlert')
    }

    const onButtonClick = () => {
      if (alert.value.buttonAction) alert.value.buttonAction()
      closeAlert()
    }

    const onCancelClick = () => {
      if (alert.value.cancelButtonAction) alert.value.cancelButtonAction()
      closeAlert()
    }

    const keyboardInput = e => {
      if (e.key === 'Escape') {
        closeAlert()
      }
    }

    useKeyboardInput(keyboardInput)

    return {
      ...computedProps,
      alert,
      closeAlert,
      onButtonClick,
      onCancelClick,
      modal,
    }
  },
}
</script>
