<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.875 5V14.4577C1.875 15.5623 2.77043 16.4577 3.875 16.4577H7.23996C7.84222 16.4577 8.41241 16.7291 8.79219 17.1966L10.147 18.8641C10.9675 19.8739 12.5186 19.8438 13.2993 18.8029L14.4581 17.2577C14.8358 16.7541 15.4286 16.4577 16.0581 16.4577H19.375C20.4796 16.4577 21.375 15.5623 21.375 14.4577V5C21.375 3.89543 20.4796 3 19.375 3H3.875C2.77043 3 1.875 3.89543 1.875 5Z"
      stroke="#C4C4C4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
