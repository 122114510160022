<template>
  <component v-if="component" v-bind="$attrs" :is="component" />
  <span
    v-else-if="source === 'custom'"
    v-bind="$attrs"
    :class="customIconClass"
  />
</template>

<script>
import camelCase from 'lodash/camelCase'

export default {
  inheritAttrs: false,
  props: {
    component: {
      type: Object,
      required: false,
    },
    source: {
      type: String,
      default: 'custom',
    },
    name: {
      type: String,
      required: false,
    },
  },
  computed: {
    // Gets a CSS module class, e.g. iconCustomLogo
    customIconClass() {
      return this.$style[camelCase('icon-custom-' + this.name)]
    },
  },
}
</script>
