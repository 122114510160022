<template>
  <base-label :class="labelClass" :for="name">
    {{ label }}
    <p v-if="error" class="text-error text-xs font-medium">{{ error }}</p>
    <input
      :type="type"
      :name="name"
      :value="modelValue"
      @input="
        $emit(
          'update:modelValue',
          type === 'number'
            ? parseInt($event.target.value)
            : $event.target.value
        )
      "
      v-bind="$attrs"
      :placeholder="errorMessage || placeholder"
      :autocomplete="autocomplete ? 'on' : 'off'"
      class="block p-3 w-full bg-white border rounded"
      :class="{
        'mt-2 sm:mt-4': label,
        'border-red-600 placeholder-red-600': hasErrors && dirty,
      }"
    />
    <span
      v-if="hasErrors && (type === 'date' || modelValue)"
      class="text-red-600 text-xs"
    >
      {{ errorMessage }}
    </span>
  </base-label>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    error: String,
    autocomplete: Boolean,
    modelValue: [String, Number],
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    type: {
      type: String,
      default: 'text',
      // Only allow types that essentially just render text boxes.
      validator(value) {
        return [
          'date',
          'email',
          'number',
          'password',
          'search',
          'tel',
          'text',
          'url',
        ].includes(value)
      },
    },
    validation: { type: Object, default: () => {} },
  },

  computed: {
    name() {
      return this.label?.toLowerCase()
    },
    hasErrors() {
      return !!this.validation?.$errors?.length
    },
    errorMessage() {
      return this.validation?.$errors?.[0]?.$message
    },
    dirty() {
      return this.validation?.$dirty
    },
  },
}
</script>
