import EventsRepository from '@/api/repositories/events'

export const state = {
  events: [],
  attendingEvents: [],
}

export const getters = {
  events(state) {
    return state.events
  },
  event(state) {
    return state.event
  },
  attendingEvents(state) {
    return state.attendingEvents
  },
  cityHappenings(state) {
    return state.events.filter(({ eventType }) => eventType === 'city_happening')
  },
  sectionLEvents(state) {
    return state.events.filter(({ eventType }) => eventType === 'section_l_event')
  }
}

export const mutations = {
  SET_EVENTS(state, events) {
    state.events = events
  },
  SET_EVENT(state, event) {
    state.event = event
  },
  RESET_EVENT(state) {
    state.event = null
  },
  APPEND_COMMENT(state, comment) {
    state.event.comments.push(comment)
  },
  SET_ATTENDING_EVENTS(state, attendingEvents) {
    state.attendingEvents = attendingEvents
  },
}

export const actions = {
  async fetchEvents({ commit }) {
    const response = await EventsRepository.getEvents()
    const { events } = response.data
    commit('SET_EVENTS', events)
  },
  async fetchEvent({ commit }, { eventId }) {
    commit('RESET_EVENT')
    const response = await EventsRepository.getEvent(eventId)
    const event = response.data
    commit('SET_EVENT', event)
  },
  appendComment({ commit }, { comment }) {
    commit('APPEND_COMMENT', comment)
  },
  async attendEvent({ commit }, { eventId }) {
    const response = await EventsRepository.attendEvent(eventId)
    commit('SET_EVENT', response.data)
  },
  async cancelAttendance({ commit }, { eventId }) {
    const response = await EventsRepository.cancelAttendance(eventId)
    commit('SET_EVENT', response.data)
  },
  async createInterest({ commit }, { eventId }) {
    const response = await EventsRepository.createInterest(eventId)
    commit('SET_EVENT', response.data)
  },
  async destroyInterest({ commit }, { eventId, interestId }) {
    const response = await EventsRepository.destroyInterest({
      eventId,
      interestId,
    })
    commit('SET_EVENT', response.data)
  },
  async fetchAttendingEvents({ commit }) {
    const response = await EventsRepository.getEvents({ attending: true })
    const { events } = response.data
    commit('SET_ATTENDING_EVENTS', events)
  },
}
