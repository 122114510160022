<template>
  <transition>
    <div v-if="visible" class="absolute">
      <div
        class="translate-center max-h-[90%] fixed z-50 flex flex-col gap-y-3 items-center px-6 py-8 w-11/12 bg-white rounded-lg shadow-lg overflow-scroll sm:gap-y-8 sm:p-12 sm:max-w-2xl lg:max-w-2xl"
        v-bind="$attrs"
      >
        <div
          v-if="!hideX"
          @click="close"
          class="absolute right-3 top-3 text-gray-500 text-xl leading-none cursor-pointer"
        >
          ✕
        </div>
        <slot />
      </div>
      <overlay class="z-20 cursor-pointer" @click="close" />
    </div>
  </transition>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script lang="ts" setup>
import Overlay from '@/components/Overlay.vue'
import { ref } from 'vue'

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'show'): void
}>()

withDefaults(defineProps<{ hideX?: boolean }>(), { hideX: false })

const visible = ref(false)

const close = () => {
  visible.value = false
  emit('close')
}

const show = () => {
  visible.value = true
  emit('show')
}

defineExpose({ close, show })
</script>
