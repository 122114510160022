import Repository from './repository'
import type Event from '@/types/api/types/Event'

export default {
  getEvents(params = {}) {
    return Repository.get('/v2/user/events', { params })
  },
  getEvent(eventId) {
    return Repository.get<Event>(`/v2/user/events/${eventId}`)
  },
  // Old
  attendEvent(eventId) {
    return Repository.post(`/v1/user/events/${eventId}/attendances`)
  },
  cancelAttendance(eventId) {
    return Repository.delete(`/v1/user/events/${eventId}/attendances`)
  },
  // New
  createInterest(eventId: number) {
    return Repository.post<Event>(`/v2/user/events/${eventId}/interests`)
  },
  destroyInterest({ eventId, interestId }: { eventId: number, interestId: number }) {
    return Repository.delete<Event>(
      `/v2/user/events/${eventId}/interests/${interestId}`
    )
  },
}
