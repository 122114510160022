import { Store, createStore } from 'vuex'
import dispatchActionForAllModules from '@/utils/dispatch-action-for-all-modules'
import modules from './modules'
import { InjectionKey } from 'vue'
import axios from 'axios'
import { config } from '../constants'

interface State {
  DOMLoaded: boolean
  alert: any
  cloudinaryTimestamp: number
  featureFlags: string[]
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export default createStore<State>({
  state: {
    DOMLoaded: false,
    alert: null,
    cloudinaryTimestamp: Math.floor(Date.now() / 1000),
    featureFlags: [],
  },
  getters: {
    DOMLoaded(state) {
      return state.DOMLoaded
    },
    appLoading(state) {
      return !state.DOMLoaded
    },
    alert(state) {
      return state.alert
    },
    cloudinaryTimestamp(state) {
      return state.cloudinaryTimestamp
    },
    featureFlags(state) {
      return state.featureFlags
    },
  },
  mutations: {
    SET_DOM_LOADED(state) {
      state.DOMLoaded = true
    },
    SET_ALERT(state, alert) {
      state.alert = alert
    },
    SET_FEATURE_FLAGS(state, featureFlags) {
      state.featureFlags = featureFlags
    },
  },
  actions: {
    setDOMLoaded({ commit }) {
      commit('SET_DOM_LOADED')
    },
    setAlert({ commit }, alert) {
      // { title, messages, buttonText, cancelButtonText, buttonAction, cancelButtonAction, classes, hideX }
      commit('SET_ALERT', alert)
    },
    async fetchFeatureFlags({ commit }) {
      try {
        const { data: flags } = await axios.get(config.featureFlagsPath)
        commit('SET_FEATURE_FLAGS', [...(flags || [])])
      } catch (e) {
        console.warn(e)
        commit('SET_FEATURE_FLAGS', [])
      }
    },
    resetAlert({ commit }) {
      commit('SET_ALERT', null)
    },
    resetStore({ dispatch }) {
      dispatch('bookings/resetBooking')
      dispatch('checkins/resetCheckin')
      dispatch('auth/logOut')
    },
    // === ActionCable (Notifications) === //
    async onReceived({ commit }, data) {
      const processNotification = data => {
        const { chat, message, type } = data
        if (type === 'ChatNotification') {
          commit('chats/UPDATE_CHAT', { chat }, { root: true })
          commit(
            'chats/ADD_MESSAGE',
            { message, chatId: chat.id },
            { root: true }
          )
        }
      }
      if (data.error) {
        throw data
      } else {
        const { notifications } = data
        if (notifications) {
          notifications.forEach(data => processNotification(data))
        } else {
          processNotification(data)
        }
      }
    },
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: import.meta.env.NODE_ENV !== 'production',
})

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
