<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.5C6.51451 4.5 1.94599 8.82962 0.95108 13.7633C0.732731 14.8461 1.64543 15.75 2.75 15.75H21.25C22.3546 15.75 23.2673 14.8461 23.0489 13.7633C22.054 8.82962 17.4855 4.5 12 4.5Z"
      fill="#008A6A"
      stroke="#008A6A"
      stroke-width="1.5"
    />
    <line
      x1="9"
      y1="1.5"
      x2="15"
      y2="1.5"
      stroke="#008A6A"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <line
      x1="3"
      y1="20.25"
      x2="20.25"
      y2="20.25"
      stroke="#008A6A"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
