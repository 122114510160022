import store from '@/store'
import router from '@/router'
// import i18n from '@/i18n'

// const { t } = i18n.global

const ErrorHandler = class {
  handle(e) {
    const error = e?.response?.data || e?.response || e

    if (e.toJSON().message == 'Network Error') {
      this.networkError()
      // } else if (error.type === 'ErrorType') {
      //   this.handleError()
    } else if (
      store.getters['auth/loggedIn'] &&
      e?.response?.status === 401 &&
      error.type === 'NotAuthorizedError'
    ) {
      this.unauthorizedError()
    } else if (store.getters['auth/loggedIn'] && e?.response?.status === 401) {
      this.notLoggedInError()
    } else if (error.full_messages || error.messages || error.message) {
      this.defaultError(error)
    }

    return Promise.reject(error)
  }

  defaultError(error) {
    store.dispatch('setAlert', {
      title: 'Error',
      messages: error.full_messages || error.messages || [error.message],
      buttonText: null,
    })
  }

  networkError() {
    store.dispatch('setAlert', {
      title: 'Seems like there are connection issues',
      messages: [
        'Check your internet connection.',
        'Make sure the API is available.',
      ],
      buttonText: 'Refresh',
    })
  }

  unauthorizedError() {
    store.dispatch('setAlert', {
      title: 'Unauthorized',
      messages: [
        'Sorry, you are not allowed to perform this action.',
        'If this is unexpected, please contact one of our staff!',
      ],
      buttonText: 'Back',
      buttonAction: () => router.go(-1),
    })
  }

  notLoggedInError() {
    store.dispatch('setAlert', {
      title: 'You need to be logged in',
      messages: [
        'Sorry, it seems that you have been logged out.',
        'Please log back in to access this page.',
      ],
      buttonText: 'Login',
      buttonAction: () => router.push({ name: 'Logout' }),
    })
    router.push({ name: 'Logout' })
  }
}

export default new ErrorHandler()
