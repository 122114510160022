import GuestsRepository from '@/api/repositories/guests'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async fetchGuest(_, guestEmail) {
    const response = await GuestsRepository.getGuest(guestEmail)
    return response.data
  },
  async updateGuest(_, guest) {
    return await GuestsRepository.patchGuest(guest)
  },
}
