<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9579 23.0737C18.097 23.0737 23.0737 18.097 23.0737 11.9579C23.0737 5.81881 18.097 0.842102 11.9579 0.842102C5.81882 0.842102 0.842108 5.81881 0.842108 11.9579C0.842108 18.097 5.81882 23.0737 11.9579 23.0737Z"
      fill="#008A6A"
      stroke="#008A6A"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M13.8105 13.3053L7.49474 16.5053L10.779 10.2737L17.0105 6.98946L13.8105 13.3053Z"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M10.779 10.2737L13.7263 13.221"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M12.2947 5.72631C12.7598 5.72631 13.1368 5.34929 13.1368 4.8842C13.1368 4.41912 12.7598 4.0421 12.2947 4.0421C11.8297 4.0421 11.4526 4.41912 11.4526 4.8842C11.4526 5.34929 11.8297 5.72631 12.2947 5.72631Z"
      fill="white"
      stroke="white"
      stroke-width="0.5889"
      stroke-miterlimit="10"
    />
    <path
      d="M12.2947 20.0421C12.7598 20.0421 13.1368 19.6651 13.1368 19.2C13.1368 18.7349 12.7598 18.3579 12.2947 18.3579C11.8297 18.3579 11.4526 18.7349 11.4526 19.2C11.4526 19.6651 11.8297 20.0421 12.2947 20.0421Z"
      fill="white"
      stroke="white"
      stroke-width="0.5889"
      stroke-miterlimit="10"
    />
    <path
      d="M4.88422 12.6316C5.3493 12.6316 5.72632 12.2545 5.72632 11.7895C5.72632 11.3244 5.3493 10.9474 4.88422 10.9474C4.41913 10.9474 4.04211 11.3244 4.04211 11.7895C4.04211 12.2545 4.41913 12.6316 4.88422 12.6316Z"
      fill="white"
      stroke="white"
      stroke-width="0.5889"
      stroke-miterlimit="10"
    />
    <path
      d="M19.2 12.6316C19.6651 12.6316 20.0421 12.2545 20.0421 11.7895C20.0421 11.3244 19.6651 10.9474 19.2 10.9474C18.7349 10.9474 18.3579 11.3244 18.3579 11.7895C18.3579 12.2545 18.7349 12.6316 19.2 12.6316Z"
      fill="white"
      stroke="white"
      stroke-width="0.5889"
      stroke-miterlimit="10"
    />
  </svg>
</template>
