import NeighborRepository from '@/api/repositories/neighbors'

export const state = {
  // neighbors are object which key is the ID of the profile
  // and the value is the profile information
  // { <profile_id>: { <profile_information> } }
  neighbors: {
    guests: {},
    staffs: {},
  },
}

export const getters = {
  staffs(state) {
    return state.neighbors.staffs
  },
  guests(state) {
    return state.neighbors.guests
  },
}

export const mutations = {
  SET_NEIGHBORS(state, { guests, staffs }) {
    state.neighbors.guests = guests
    state.neighbors.staffs = staffs
  },
}

export const actions = {
  async fetchNeighbors({ commit, rootGetters }) {
    const propertyId = rootGetters['auth/currentProfile'].property?.id
    const response = await NeighborRepository.getNeighbors({ propertyId })
    const guestsAndStaffsData = response.data
    commit('SET_NEIGHBORS', guestsAndStaffsData)
    return guestsAndStaffsData
  },
}
