import Auth from '@/api/repositories/auth'
import getSavedState from '@/utils/get-saved-state'
import saveState from '@/utils/save-state'
import clearLocalStorage from '@/utils/clear-local-storage'
import { config } from '@/constants'

interface AuthQueryString {
  accessToken?: string
  tokenType?: string
  client?: string
  expiry?: string
  uid?: string
}

const removeEmptyValues = object =>
  Object.entries(object || {}).reduce((acc, [k, v]) => {
    const valueExists = v !== undefined && v !== null
    return {
      ...(valueExists ? { [k]: v } : {}),
      ...acc,
    }
  }, {})

const AUTH_LOGGED_IN_HEADERS = [
  'access-token',
  'token-type',
  'client',
  'expiry',
  'uid',
]

export const state = {
  currentUser: getSavedState('auth.currentUser') || { profile: {} },
  headers: getSavedState('auth.headers'),
  applianceGuideLang: getSavedState('auth.applianceGuideLang') || 'en'
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', state.currentUser)
  },
  SET_CURRENT_BOOKING(state, newBooking) {
    state.currentUser.currentBooking = newBooking
    saveState('auth.currentUser', state.currentUser)
  },
  SET_CURRENT_PROFILE(state, newValue) {
    state.currentUser.profile = { ...state.currentUser.profile, ...newValue }
    saveState('auth.currentUser', state.currentUser)
  },
  SET_PROPERTY(state, newValue) {
    state.currentUser.profile.property = newValue
    saveState('auth.currentUser', state.currentUser)
  },
  SET_AUTH_HEADERS(state, newValue) {
    state.headers = newValue
    saveState('auth.headers', state.headers)
  },
  SET_APPLIANCE_GUIDE_LANG(state, newLang) {
    state.applianceGuideLang = newLang
    saveState('auth.applianceGuideLang', state.applianceGuideLang)
  }
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return (
      Object.keys(state.currentUser?.profile || {}).length > 0 &&
      AUTH_LOGGED_IN_HEADERS.every(
        requiredHeader => state.headers?.[requiredHeader]
      )
    )
  },
  currentUser(state) {
    return state.currentUser
  },
  isStaff(state) {
    return state.currentUser?.profile?.userType === 'Staff'
  },
  currentBooking(state) {
    return state.currentUser?.currentBooking
  },
  hasActiveBooking(state) {
    return !!state.currentUser?.currentBooking?.active
  },
  upcomingBookings(state) {
    return state.currentUser?.upcomingBookings
  },
  hasUpcomingBookings(state) {
    return state.currentUser?.upcomingBookings?.length > 0
  },
  property(state, getters) {
    // When there is a current (active) booking, return property of that
    if (state.currentUser?.currentBooking) return state.currentUser?.currentBooking?.property

    // If not, check the next upcoming bookings, return the property of that
    return state.currentUser.upcomingBookings?.[0]?.property
    // otherwise, return null
  },
  currentProfile(state) {
    return {
      name: '',
      languageList: [],
      profession: '',
      city: '',
      hobbyList: [],
      interestList: [],
      photoKey: '',
      socialMediaLink: '',
      ...removeEmptyValues(state.currentUser?.profile),
    }
  },
  profileComplete(state) {
    const profile = state.currentUser?.profile
    return (
      profile?.name &&
      profile.languageList.length &&
      profile.profession &&
      profile.city
    )
  },
  saunaTermsAgreed(state) {
    return Boolean(state.currentUser?.profile?.saunaTermsAgreedAt)
  },
  headers(state) {
    return state.headers
  },
  wsConnectionUrl(state) {
    const url = new URL(`wss://${config.apiHost}/cable`)
    if (config.environment === 'development') url.protocol = 'ws:'
    if (state.headers) {
      const { uid, client, 'access-token': token } = state.headers
      Object.entries({ uid, client, token }).forEach(entry => {
        url.searchParams.append(entry[0], entry[1])
      })
    }
    return url.href
  },
  applianceGuideLang(state) {
    return state.applianceGuideLang
  }
}

export const actions = {
  // This `init()` is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // init({ state, dispatch }) {},

  // Logs in the current user.
  logIn({ commit }, { email, redirectTo }: { email?: string; redirectTo?: string } = {}) {
    return Auth.logIn({ email, redirectTo }).then(response => {
      const user = response.data.data
      const headers = response.headers
      commit('SET_CURRENT_USER', user)
      commit('SET_AUTH_HEADERS', headers)
      return user
    })
  },

  async reportLoginSuccess({ dispatch }, user) {
    if (user && user.profile?.id) {
      await dispatch('profiles/updateProfile', {
        id: user.profile?.id,
        profile: { lastLoggedIn: new Date().toISOString() },
      }, { root: true })
    }
    return user
  },

  async magicLogIn({ commit, dispatch }, query: AuthQueryString = {}) {
    const headers = {
      'access-token': query.accessToken,
      'token-type': query.tokenType,
      client: query.client,
      expiry: query.expiry,
      uid: query.uid,
    }
    commit('SET_AUTH_HEADERS', headers)
    const user = await dispatch('validateToken').catch(() => { })
    if (!user) return
    await dispatch('sauna/fetchLatestReservation', {}, { root: true })
    await dispatch('neighborhoods/fetchAndSetNeighborhoods')
    return await dispatch('reportLoginSuccess', user)
  },

  // Validates current credentials.
  validateToken({ commit, getters }) {
    return Auth.validateToken({ admin: getters.isStaff }).then(response => {
      const user = response.data.data
      commit('SET_CURRENT_USER', user)
      return user
    })
  },

  // Updates auth headers.
  updateHeaders({ commit }, headers) {
    commit('SET_AUTH_HEADERS', headers)
  },

  // Logs out the current user.
  async logOut({ getters, commit }) {
    if (getters.loggedIn) {
      try {
        await Auth.logOut({ admin: getters.isStaff })
      } finally {
        commit('SET_CURRENT_USER', null)
        commit('SET_AUTH_HEADERS', null)
        clearLocalStorage()
        // router.go() // Refreshes the page which resets the in-memory store
      }
    }
  },

  signUp(_, credentials) {
    return Auth.signUp(credentials)
      .then(response => {
        if (response.data.status !== 'success') {
          throw response.data.errors
        }
      })
      .catch(error => {
        throw error.response.data.errors
      })
  },

  setCurrentUser({ commit }, newUser) {
    commit('SET_CURRENT_USER', newUser)
  },

  setCurrentBooking({ commit }, newBooking) {
    commit('SET_CURRENT_BOOKING', newBooking)
  },

  setCurrentProfile({ commit }, newProfile) {
    commit('SET_CURRENT_PROFILE', newProfile)
  },

  setApplianceGuideLang({ commit }, newLang) {
    commit('SET_APPLIANCE_GUIDE_LANG', newLang)
  }
}
