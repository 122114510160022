<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="27"
      height="27"
      rx="3.5"
      fill="#008A6A"
      stroke="#008A6A"
    />
    <path
      d="M20.6667 9L11.5 18.1667L7.33334 14"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
