import CommentsRepository from '@/api/repositories/comments'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async postComment(_, { content, eventId }) {
    const response = await CommentsRepository.postComment({ content, eventId })
    return response.data
  },
}
