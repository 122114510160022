import Repository from './repository'

export default {
  getProfiles({ propertyId, all }) {
    return Repository.get(`/v1/user/properties/${propertyId}/profiles`, {
      params: { all },
    })
  },
  postProfile({ profile }) {
    return Repository.post(`/v1/user/profiles`, { profile })
  },
  patchProfile({ id, profile }) {
    return Repository.patch(`/v1/user/profiles/${id}`, { profile })
  },
}
