<template>
  <router-view />
  <alert-modal />
</template>

<script>
import AlertModal from '@/components/AlertModal'
import { useStore } from 'vuex'
import { key } from './store'
import { computed, nextTick, onMounted, ref } from 'vue'

export default {
  components: { AlertModal },

  setup() {
    const store = useStore(key)

    // for mobile vh
    const setViewHeight = async () => {
      await nextTick()
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    setViewHeight()
    window.addEventListener('resize', setViewHeight)

    const loggedIn = computed(() => store.getters['auth/loggedIn'])

    onMounted(async () => {
      // Check authentication and get latest user / current booking data
      if (store.getters['auth/loggedIn']) {
        await store.dispatch('auth/validateToken')
      }
      // Fetch feature flags
      await store.dispatch('fetchFeatureFlags')

      store.dispatch('setDOMLoaded')
    })

    const connectCable = async () => {
      await store.dispatch('cable/connect', { channel: 'NotificationsChannel' })
    }

    const disconnectCable = async () => await store.dispatch('cable/disconnect')

    const connected = ref(false)

    return { loggedIn, connectCable, disconnectCable, connected }
  },

  // === ActionCable === //
  async created() {
    this.connectCable()
  },

  beforeUnmount() {
    this.disconnectCable()
  },

  watch: {
    loggedIn: function (isLoggedIn) {
      if (!this.connected && isLoggedIn) this.connectCable()
      if (this.connected && !isLoggedIn) this.disconnectCable()
    },
  },

  channels: {
    NotificationsChannel: {
      connected() {
        this.connected = true
      },
      rejected() {
        console.warn('Connection to cable rejected.')
      },
      received(data) {
        this.$store.dispatch('onReceived', data)
      },
      disconnected() {
        this.connected = false
      },
    },
  },
}
</script>

<style lang="postcss">
@import './assets/stylesheets/index.css';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
