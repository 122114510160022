import BookingsRepository from '@/api/repositories/bookings'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async fetchBookings() {
    try {
      const response = await BookingsRepository.getBookings()
      return response.data.bookings
    } catch (error) {
      console.warn(error)
    }
  },
  async fetchBooking(_, { bookingNo }) {
    try {
      const response = await BookingsRepository.getBooking({
        bookingNo,
      })
      return response.data
    } catch (error) {
      console.warn(error)
    }
  },
}
