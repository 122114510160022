import Repository from './repository'

export default {
  getGuest(email) {
    return Repository.get(`/v1/guests/find`, {
      params: { email },
    })
  },
  patchGuest({ id, guest }) {
    return Repository.patch(`/v1/user/guests/${id}`, { guest })
  },
}
