import Repository from './repository'

export default {
  getChats() {
    return Repository.get(`/v1/user/chats`)
  },
  getChat({ chatId }) {
    return Repository.get(`/v1/user/chats/${chatId}`)
  },
  postMessage({ chatId, content }) {
    return Repository.post(`/v1/user/chats/${chatId}/messages`, {
      message: { content },
    })
  },
}
