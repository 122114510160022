<template>
  <base-label :class="labelClass" :for="name">
    {{ label }}
    <select
      :name="name"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
      class="block p-3 w-full h-12 bg-white border rounded"
      :class="{ 'text-gray-400': modelValue === null }"
    >
      <option :disabled="!allowEmpty" value>
        {{ placeholder }}
      </option>
      <option
        v-for="(value, key) in optionsObject"
        :value="key"
        :key="key"
        :selected="modelValue == key"
      >
        {{ value }}
      </option>
    </select>
  </base-label>
</template>

<script>
import humanize from '@/utils/humanize'

export default {
  inheritAttrs: false,

  props: {
    modelValue: [Number, String],
    allowEmpty: { type: Boolean, default: true },
    placeholder: {
      type: String,
      default: 'Select',
    },
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: [String, Array],
      required: false,
    },
    options: [Array, Object],
    // Pass options either as an array of string
    // ['my_option', ... ] => will be rendered as 'My option'
    // or as an object { 'key': 'Option name', ... }
  },

  computed: {
    name() {
      return this.label?.toLowerCase()
    },
    optionsObject() {
      // If options is not an array, just return it
      if (!Array.isArray(this.options)) return this.options

      // Otherwise, convert it to an Object using index as keys
      return Object.fromEntries(this.options.map(el => [el, humanize(el)]))
    },
  },

  methods: {
    humanize,
  },
}
</script>
