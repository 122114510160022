export const state = {}

export const getters = {
  property(_state, _getters, _rootState, rootGetters) {
    return rootGetters['auth/property']
  },
  hasSauna(_state, getters) {
    return ['SLSW', 'TP'].includes(getters['property']?.acronym)
  },
}

export const mutations = {}

export const actions = {}
