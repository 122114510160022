import Repository from './repository'

export default {
  getBookings() {
    return Repository.get(`/v1/user/bookings`)
  },
  getBooking({ bookingNo } = {}) {
    return Repository.get(`/v1/user/bookings/${bookingNo}`)
  },
}
