export async function storeMiddleware(to, from, next) {
  if (!to.query.q) return
  const decode64 = s => atob(s)

  const url = new URL(decode64(to.query.q), window.location.origin)
  const queryParams = new URLSearchParams(url.search)

  // Build new query Object from remaining query params
  const query = Object.fromEntries([...queryParams.entries()])

  next({ path: url.pathname, query })
  throw 'Redirected'
}
