<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
      fill="#008A6A"
      stroke="#008A6A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="3.99986"
      y="14.1429"
      width="16"
      height="6.85714"
      rx="3"
      fill="#008A6A"
      stroke="#008A6A"
      stroke-width="1.5"
    />
  </svg>
</template>
