import ProfileRepository from '@/api/repositories/profiles'

export const state = {
  // profiles are object which key is the ID of the profile
  // and the value is the profile information
  // { <profile_id>: { <profile_information> } }
  profiles: {},
}

export const getters = {
  profiles(state) {
    return state.profiles
  },
}

export const mutations = {
  SET_PROFILES(state, profiles) {
    state.profiles = profiles
  },
  UPDATE_PROFILES(state, profiles) {
    state.profiles = { ...state.profiles, ...profiles }
  },
}

export const actions = {
  async fetchProfiles(
    { commit, rootGetters },
    { all = false, clearCache = false } = {}
  ) {
    const propertyId = rootGetters['auth/currentProfile'].property?.id
    const response = await ProfileRepository.getProfiles({ propertyId, all })
    const profiles = response.data.profiles // array of profile objects
    const profilesObject = {}
    profiles.forEach(profile => {
      profilesObject[profile.id] = profile
    })
    commit(clearCache ? 'SET_PROFILES' : 'UPDATE_PROFILES', profilesObject)
    return profilesObject
  },
  async createProfile(_, { profile }) {
    return await ProfileRepository.postProfile({ profile })
  },
  async updateProfile(_, { id, profile }) {
    return await ProfileRepository.patchProfile({ id, profile })
  },
}
